/* All classes used must begin with "__se__". */
/* used color #000 , #fff , #CCC, #f9f9f9 */
/* font color #333, background color: #fff */
/* grey color #e1e1e1 , #d1d1d1 , #c1c1c1 , #b1b1b1 */
/* blue color #c7deff , #80bdff , #3f9dff , #4592ff */
/* red color #b94a48 , #f2dede , #eed3d7 */

/* suneditor contents */
.sun-editor-editable {
    font-family: Helvetica Neue;
    font-size: 13px;
    color: #333;
    background-color: #fff;
    line-height: 1.5;
    word-break: normal;
    word-wrap: break-word;
    padding: 16px;
    margin: 0;
}

.sun-editor-editable * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

/* RTL - editable */
.sun-editor-editable.se-rtl * {
    direction: rtl;
}

/** controllers on tag */
.sun-editor-editable td, .sun-editor-editable th, 
.sun-editor-editable figure, .sun-editor-editable figcaption, .sun-editor-editable img, 
.sun-editor-editable iframe, .sun-editor-editable video, .sun-editor-editable audio {
    position: relative;
}

/* float */
.sun-editor-editable .__se__float-left {
    float: left;
}
.sun-editor-editable .__se__float-right {
    float: right;
}
.sun-editor-editable .__se__float-center {
    float: center;
}
.sun-editor-editable .__se__float-none {
    float: none;
}

/** span */
.sun-editor-editable span {
    display: inline;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
}
/* katex */
.sun-editor-editable span.katex {
    display: inline-block;
}
.sun-editor-editable span.katex * {
    direction: ltr;
}

/* a */
.sun-editor-editable a {
    color: #004cff;
    text-decoration: none;
}
.sun-editor-editable span[style~="color:"] a {
    color: inherit;
}
.sun-editor-editable a:hover, .sun-editor-editable a:focus {
    cursor: pointer;
    color: #0093ff;
    text-decoration: underline;
}
.sun-editor-editable a.on {
    color: #0093ff;
    background-color: #e8f7ff;
}

/* pre */
.sun-editor-editable pre {
    display: block;
    padding: 8px;
    margin: 0 0 10px;
    font-family: monospace;
    color: #666;
    line-height: 1.45;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    white-space: pre-wrap !important;
    word-wrap: break-word;
    overflow: visible;
}

/* ol, ul */
.sun-editor-editable ol {
    list-style-position: outside;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.sun-editor-editable ul {
    list-style-position: outside;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.sun-editor-editable li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px;
}
/* nested list ol, ul */
.sun-editor-editable ol ol, .sun-editor-editable ol ul, .sun-editor-editable ul ol, .sun-editor-editable ul ul {
    margin: 0;
}
.sun-editor-editable ol ol, .sun-editor-editable ul ol {
    list-style-type: lower-alpha;
}
.sun-editor-editable ol ol ol, .sun-editor-editable ul ol ol, .sun-editor-editable ul ul ol {
    list-style-type: upper-roman;
}
.sun-editor-editable ul ul, .sun-editor-editable ol ul {
    list-style-type: circle;
}
.sun-editor-editable ul ul ul, .sun-editor-editable ol ul ul, .sun-editor-editable ol ol ul {
    list-style-type: square;
}

/* sub, sup */
.sun-editor-editable sub, .sun-editor-editable sup {
    font-size: 75%;
    line-height: 0;
}
.sun-editor-editable sub {
    vertical-align: sub;
}
.sun-editor-editable sup {
    vertical-align: super;
}

/** format style */
/* p */
.sun-editor-editable p {
    display: block;
    margin: 0 0 10px;
}

/* div */
.sun-editor-editable div {
    display: block;
    margin: 0;
    padding: 0;
}

/* blockquote */
.sun-editor-editable blockquote {
    display: block;
    font-family: inherit;
    font-size: inherit;
    color: #999;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    border-style: solid;
    border-width: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-color: #b1b1b1;
    padding-left: 20px;
    padding-right: 5px;
    border-left-width: 5px;
    border-right-width: 0px;
}
.sun-editor-editable blockquote blockquote {
    border-color: #c1c1c1;
}
.sun-editor-editable blockquote blockquote blockquote {
    border-color: #d1d1d1;
}
.sun-editor-editable blockquote blockquote blockquote blockquote {
    border-color: #e1e1e1;
}
/* RTL - blockquote */
.sun-editor-editable.se-rtl blockquote {
    padding-left: 5px;
    padding-right: 20px;
    border-left-width: 0px;
    border-right-width: 5px;
}

/* h1 */
.sun-editor-editable h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
/* h2 */
.sun-editor-editable h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
/* h3 */
.sun-editor-editable h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
/* h4 */
.sun-editor-editable h4 {
    display: block;
    font-size: 1em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
/* h5 */
.sun-editor-editable h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
/* h6 */
.sun-editor-editable h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

/* hr */
.sun-editor-editable hr {
    display: flex;
    border-width: 1px 0 0;
    border-color: black;
    border-image: initial;
    height: 1px;
}
.sun-editor-editable hr.__se__solid {
    border-style: solid none none;
}
.sun-editor-editable hr.__se__dotted {
    border-style: dotted none none;
}
.sun-editor-editable hr.__se__dashed {
    border-style: dashed none none;
}
.sun-editor-editable hr.on {
    border-color: #4592ff;
    -webkit-box-shadow:0 0 0 0.1rem #c7deff;
    box-shadow:0 0 0 0.1rem #c7deff;
}

/* table */
.sun-editor-editable table {
    display: table;
    table-layout: auto !important;
    border: 1px solid rgb(204, 204, 204);
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
}

/* RTL - table */
.sun-editor-editable.se-rtl table {
    margin: 0 0 10px auto;
}

.sun-editor-editable table thead {
    border-bottom: 2px solid #333;
}
.sun-editor-editable table tr {
    border: 1px solid #efefef;
}
.sun-editor-editable table th {
    background-color: #f3f3f3;
}
.sun-editor-editable table th, .sun-editor-editable table td {
    border: 1px solid #e1e1e1;
    padding:0.4em;
    background-clip: padding-box;
}
/** table style class */
.sun-editor-editable table.se-table-size-auto {
    width: auto !important;
}
.sun-editor-editable table.se-table-size-100 {
    width: 100% !important;
}
.sun-editor-editable table.se-table-layout-auto {
    table-layout: auto !important;
}
.sun-editor-editable table.se-table-layout-fixed {
    table-layout: fixed !important;
}
/** table - select class */
.sun-editor-editable table td.se-table-selected-cell, .sun-editor-editable table th.se-table-selected-cell {
    outline: 1px double #4592ff;
}
.sun-editor-editable.se-disabled * {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/** component (image, iframe video) */
.sun-editor-editable .se-component {
    display: flex;
    padding: 1px;
    margin: 0 0 10px;
}
.sun-editor-editable[contenteditable=true] .se-component {
    outline: 1px dashed #e1e1e1;
}
/** component copy effect */
.sun-editor-editable[contenteditable=true] .se-component.se-component-copy {
    -webkit-box-shadow:0 0 0 0.2rem #80bdff; box-shadow:0 0 0 0.2rem #3f9dff; transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/** image, video .. */
.sun-editor-editable img, .sun-editor-editable iframe, .sun-editor-editable video, .sun-editor-editable audio {
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    max-width: 100%;
}
/*  image, video - select index  */
.sun-editor-editable[contenteditable="true"] figure::after {
    position: absolute;
    content: '';
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    display: block;
    background: rgba(0,0,0,0);
}
.sun-editor-editable[contenteditable="true"] figure a, .sun-editor-editable[contenteditable="true"] figure img, .sun-editor-editable[contenteditable="true"] figure iframe, .sun-editor-editable[contenteditable="true"] figure video {
    z-index: 0;
}
.sun-editor-editable[contenteditable="true"] figure figcaption {
    display: block;
    z-index: 2;
}
.sun-editor-editable[contenteditable="true"] figure figcaption:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #c7deff;
    box-shadow: 0 0 0 0.2rem #c7deff;
}

/** image, video iframe figure cover */
.sun-editor-editable .se-image-container, .sun-editor-editable .se-video-container {
    width: auto;
    height: auto;
    max-width: 100%;
}
.sun-editor-editable figure {
    display: block;
    outline: none;
    margin: 0;
    padding: 0;
}
.sun-editor-editable figure figcaption {
    padding: 1em .5em;
    margin: 0;
    background-color: #f9f9f9;
    outline: none;
}
.sun-editor-editable figure figcaption p{
    line-height: 2;
    margin: 0;
}

/* image */
.sun-editor-editable .se-image-container a img {
    padding: 1px;
    margin: 1px;
    outline: 1px solid #4592ff;
}

/** video */
.sun-editor-editable .se-video-container iframe, .sun-editor-editable .se-video-container video {
    outline: 1px solid #9e9e9e;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
}
.sun-editor-editable .se-video-container figure {
    left: 0px;
    width: 100%;
    max-width: 100%;
}

/** audio */
.sun-editor-editable audio {
    width: 300px;
    height: 54px;
}
.sun-editor-editable audio.active {
    outline: 2px solid #80bdff;
}

/** --- show blocks mode */
.sun-editor-editable.se-show-block p, .sun-editor-editable.se-show-block div, 
.sun-editor-editable.se-show-block h1, .sun-editor-editable.se-show-block h2, .sun-editor-editable.se-show-block h3, 
.sun-editor-editable.se-show-block h4, .sun-editor-editable.se-show-block h5, .sun-editor-editable.se-show-block h6, .sun-editor-editable.se-show-block li, 
.sun-editor-editable.se-show-block ol, .sun-editor-editable.se-show-block ul, 
.sun-editor-editable.se-show-block pre {
    border:1px dashed #3f9dff !important;
    padding:14px 8px 8px 8px !important;
}
.sun-editor-editable.se-show-block ol, .sun-editor-editable.se-show-block ul {
    border:1px dashed #d539ff !important;
}
.sun-editor-editable.se-show-block pre {
    border:1px dashed #27c022 !important;
}
.se-show-block p {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPAQMAAAAF7dc0AAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAaSURBVAjXY/j/gwGCPvxg+F4BQiAGDP1HQQByxxw0gqOzIwAAAABJRU5ErkJggg==') no-repeat;}
.se-show-block div {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPAQMAAAAxlBYoAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j//wcDDH+8XsHwDYi/hwNx1A8w/nYLKH4XoQYJAwCXnSgcl2MOPgAAAABJRU5ErkJggg==') no-repeat;}
.se-show-block h1 {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAfSURBVAjXY/j/v4EBhr+9B+LzEPrDeygfhI8j1CBhAEhmJGY4Rf6uAAAAAElFTkSuQmCC') no-repeat;}
.se-show-block h2 {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j/v4EBhr+dB+LtQPy9geEDEH97D8T3gbgdoQYJAwA51iPuD2haEAAAAABJRU5ErkJggg==') no-repeat;}
.se-show-block h3 {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQPy9geHDeQgN5p9HqEHCADeWI+69VG2MAAAAAElFTkSuQmCC') no-repeat;}
.se-show-block h4 {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPAQMAAADTSA1RAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j//wADDH97DsTXIfjDdiDdDMTfIRhZHRQDAKJOJ6L+K3y7AAAAAElFTkSuQmCC') no-repeat;}
.se-show-block h5 {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAlSURBVAjXY/j/v4EBhr+1A/F+IO5vYPiwHUh/B2IQfR6hBgkDABlWIy5uM+9GAAAAAElFTkSuQmCC') no-repeat;}
.se-show-block h6 {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQLy/geFDP5S9HSKOrA6KAR9GIza1ptJnAAAAAElFTkSuQmCC') no-repeat;}
.se-show-block li {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA7SURBVDhPYxgFcNDQ0PAfykQBIHEYhgoRB/BpwCfHBKWpBkaggYxQGgOgBzyQD1aLLA4TGwWDGjAwAACR3RcEU9Ui+wAAAABJRU5ErkJggg==') no-repeat;}
.se-show-block ol {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABHSURBVDhPYxgFcNDQ0PAfhKFcFIBLHCdA1oBNM0kGEmMAPgOZoDTVANUNxAqQvURMECADRiiNAWCagDSGGhyW4DRrMAEGBgAu0SX6WpGgjAAAAABJRU5ErkJggg==') no-repeat;}
.se-show-block ul {background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVDhPYxgFDA0NDf+hTBSALI5LDQgwQWmqgVEDKQcsUBoF4ItFGEBXA+QzQpmDGjAwAAA8DQ4Lni6gdAAAAABJRU5ErkJggg==') no-repeat;}

/** -- Paragraph styles custom -- */
/* Bordered */
.sun-editor-editable .__se__p-bordered, .sun-editor .__se__p-bordered {
    border-top: solid 1px #b1b1b1;
    border-bottom: solid 1px #b1b1b1;
    padding: 4px 0;
}
/* Spaced */
.sun-editor-editable .__se__p-spaced, .sun-editor .__se__p-spaced {
    letter-spacing: 1px;
}
/* Neon (https://codepen.io/GeorgePark/pen/MrjbEr) */
.sun-editor-editable .__se__p-neon, .sun-editor .__se__p-neon {
    font-weight: 200;
    font-style: italic;
    background: #000;
    color: #fff;
    padding: 6px 4px;
    border: 2px solid #fff;
    border-radius: 6px;
    text-transform: uppercase;
    animation: neonFlicker 1.5s infinite alternate;     
}
@keyframes neonFlicker {
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2px #f40,
            0 0 4px #f40,
            0 0 6px #f40,
            0 0 8px #f40,
            0 0 10px #f40;
        box-shadow:
            0 0 .5px #fff,
            inset 0 0 .5px #fff,
            0 0 2px #08f,
            inset 0 0 2px #08f,
            0 0 4px #08f,
            inset 0 0 4px #08f;        
    }
    20%, 24%, 55% {        
        text-shadow: none;
        box-shadow: none;
    }    
}

/* -- Text styles custom -- */
/* Shadow */
.sun-editor-editable .__se__t-shadow, .sun-editor .__se__t-shadow {
    text-shadow:
        -0.2rem -0.2rem 1rem #fff,
        0.2rem 0.2rem 1rem #fff,
        0 0 0.2rem #999,
        0 0 0.4rem #888,
        0 0 0.6rem #777,
        0 0 0.8rem #666,
        0 0 1rem #555;
}
/* Code */
.sun-editor-editable .__se__t-code, .sun-editor .__se__t-code {
    font-family: monospace;
    color: #666;
    background-color: rgba(27,31,35,.05);
    border-radius: 6px;
    padding: 0.2em 0.4em;
}